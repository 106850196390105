import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import FsLightbox from 'fslightbox-react';
import Layout from '../../components/layout';
// import { HelmetDatoCms } from 'gatsby-source-datocms';
import ButtonOutline from '../../components/button-outline';
import Header2 from '../../components/header2';
import Container from '../../components/container';

const WeddingPage = ({ data }) => {

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    const lightboxImages = data.allDatoCmsWedding.edges.map(
        ({ node: galleryImageItem }) => galleryImageItem.image.fluid.src
    )

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    return (
        <Layout>
            <>
                {/* <HelmetDatoCms
                    favicon={data.datoCmsSite.faviconMetaTags}
                    seo={data.datoCmsGalleryPage.seoMetaTags}
                /> */}
                <FsLightbox toggler={lightboxController.toggler}
                    slide={lightboxController.slide}
                    sources={lightboxImages}>
                </FsLightbox>
                <Container className={`space-y-16 flex flex-col items-center`}>
                    <Header2 className={`text-gray-800 text-center`}>Florystyka ślubna</Header2>
                    <div className='flex flex-wrap justify-center min-w-full'>
                        {data.allDatoCmsWedding.edges.map(({ node: galleryImageItem }, index) => (
                            <div
                                className='w-full md:w-1/2 lg:w-1/3 h-72 overflow-hidden p-2 lg:p-4'
                                key={index}
                                onClick={() => openLightboxOnSlide(index + 1)}
                                onKeyPress={() => openLightboxOnSlide(index + 1)}
                                role='button'
                                tabIndex='0'
                                aria-label='otwórz galerię zdjęć'>
                                <Img
                                    className='w-full h-full shadow-image rounded'
                                    fluid={galleryImageItem.image.fluid}
                                    alt={galleryImageItem.image.alt}>
                                </Img>
                            </div>
                        ))}
                    </div>
                    <ButtonOutline link={`/`}>Powrót</ButtonOutline>
                </Container>
            </>
        </Layout>
    )
};

export default WeddingPage;

export const query = graphql`
    query WeddingPage {
        # datoCmsGalleryPage {
        #     seoMetaTags {
        #       ...GatsbyDatoCmsSeoMetaTags
        #     }
        #   }
        #   datoCmsSite {
        #     faviconMetaTags {
        #         ...GatsbyDatoCmsFaviconMetaTags
        #     }
        # }
        allDatoCmsWedding {
            edges {
                node {
                    image {
                        filename
                        # alt
                        # title
                        fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                }
            }
        }
    }
`
